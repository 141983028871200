<template>
  <div :class="{'modal modal_wrap': modalView}">
    <div :class="{'modal_popup modal_content': modalView}">
      <div :class="{'modal__context': modalView}">
        <section class="item-types">
          <breadcrumbs v-if="!modalView"></breadcrumbs>
          <h2 class="titlePage_subtitle titlePage">Mesh Flaps</h2>

          <div class="row">
            <div class="col bordered">
              <h3 class="text">Position</h3>
              <div class="justify-content-center">
                <label class="form__label form__label_row">
                  <input v-model="settings.settings.top" type="checkbox" hidden class="form__checkbox">
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Top</span>
                </label>
              </div>

              <div class="justify-content-between">

                <label class="form__label form__label_row">
                  <input v-model="settings.settings.left" type="checkbox" hidden class="form__checkbox">
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Left</span>
                </label>

                <label class="form__label form__label_row">
                  <input v-model="settings.settings.right" type="checkbox" hidden class="form__checkbox">
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Right</span>
                </label>
              </div>

              <div class="justify-content-center">
                <label class="form__label form__label_row">
                  <input v-model="settings.settings.bottom" type="checkbox" hidden class="form__checkbox">
                  <span class="form__checkbox_icon"></span>
                  <span class="form__labelTitle">Bottom</span>
                </label>
              </div>
            </div>
          </div>

          <div class="col-12 text_center">
            <hr>
            <p class="option_note">Please note: Looking from the outside</p>
          </div>
          <template v-if="actionLinksPortalTarget">
            <portal :to="actionLinksPortalTarget">
              <button type="button" class="btn btn_transparent" @click.prevent="save"><svg-icon name="solid/check" /> Done</button>
            </portal>
          </template>
          <template v-else>
            <button type="button" class="btn btn_default" @click.prevent="save"><svg-icon name="solid/check" /> Done</button>
          </template>

        </section>
      </div>
    </div>
  </div>
</template>

<script>

    import {defineAsyncComponent} from "vue";

    const breadcrumbs = defineAsyncComponent(() => import("./../../parts/breadcrumbs"));

    import {mapState, mapGetters} from 'vuex';
  export default {
    name: "MashFlaps",

    data() {
      return {
          modalView: false,
      }
    },
    components : {
      breadcrumbs
    },

    props: ['options', 'package', 'set', 'checkIsSetPackage', 'actionLinksPortalTarget'],

    methods: {
      save() {
          this.$store.commit('orderPosition/setOption', {option: 'MFLP', selection: this.settings});
          this.$store.commit('orderPosition/optionNext', 'MFLP');
      },

      closePopup() {
        this.$store.commit('options/closeAvailable');
      },
    },
    computed: {

        settings() {
            return this.$store.getters['orderPosition/getCurrentOptionSettings'];
        },
    },
  }
</script>

<style scoped lang="scss">
  @import '../../../../src/assets/scss/utils/vars';

  .form {
    &__input {
      border-color: $navy;
    }

    .bordered {
      border: 1px solid $navy;
      padding: 10px;
      margin-bottom: 20px;
    }

    &__labelTitle {
      color: $navy
    }

    .justify-content-center, .justify-content-between {
      display: flex;
      margin: 10px;
    }

    .btn {
      margin: 20px 0;
      max-width: none;
      width: 100%;
    }
  }

  .text_white {
    position: absolute;
    bottom: 3rem;
    left: auto;
    right: 2rem;
    display: inline-block;
    width: auto;
  }
  .modal__close {
    color: $green;
  }

</style>
